/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog({ getCouponGroupList, initialValues, couponGroup }) {
  // console.log('Form Dialog rendering');
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const resetState = () => {
  };
  const handleClose = () => {
    setOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    setOpen(false);
    
    const couponGroupData = {
      cpgCode: values.cpgCode,
      cpgName: values.cpgName,
      cpgIntro: values.cpgIntro,
      cpgGiveMode: values.cpgGiveMode,
      cpgGiveLiffUrl: values.cpgGiveLiffUrl,
    };
    // console.log('coupon group data', couponGroupData);
    ApiService.CouponGroup.add(storeCode, couponGroupData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/coupon/group/add/${storeCode}`,
          requestMethod: 'POST',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getCouponGroupList();
      }
    });
    resetState();
  };
  const validationSchema = yup.object().shape({
    cpgCode: yup.string('coupon group Code').required('必填欄位'),
    cpgName: yup.string('coupon group Name').required('必填欄位'),
    cpgGiveMode: yup.string('coupon group GiveMode').required('必填欄位'),
  });

  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>新增優惠群組</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs="6">{/*左半邊*/}
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="cpgCode"
                        name="cpgCode"
                        label="優惠群組代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.cpgCode}
                        onChange={handleChange}
                        error={touched.cpgCode && Boolean(errors.cpgCode)}
                        helperText={touched.cpgCode && errors.cpgCode}
                      />
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="cpgName"
                        name="cpgName"
                        label="優惠群組名稱"
                        type="text"
                        fullWidth
                        value={values.cpgName}
                        onChange={handleChange}
                        error={touched.cpgName && Boolean(errors.cpgName)}
                        helperText={touched.cpgName && errors.cpgName}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="cpgIntro"
                        name="cpgIntro"
                        label="優惠群組介紹"
                        type="text"
                        fullWidth
                        multiline
                        value={values.cpgIntro}
                        onChange={handleChange}
                        error={touched.cpgIntro && Boolean(errors.cpgIntro)}
                        helperText={touched.cpgIntro && errors.cpgIntro}
                      />
                    </Grid>
                    <Grid item xs={6}>{/*右半邊*/}
                      <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="cpg-GiveMode">優惠發送模式</InputLabel>
                        <Select
                          labelId="cpg-GiveMode"
                          id="cpgGiveMode"
                          name="cpgGiveMode"
                          value={values.cpgGiveMode}
                          label="優惠發送模式"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            setFieldValue('cpgGiveMode', e.target.value);
                          }}>
                          <MenuItem value="GIVE_ALL">GIVE_ALL (發多張/數量發完為止)</MenuItem>
                          <MenuItem value="GIVE_ONE_BY_ONE">GIVE_ONE_BY_ONE (發一張/數量發完換下個券)</MenuItem>
                        </Select>
                      </Field>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
    </Box>
  );
}
