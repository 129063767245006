/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field, FieldArray, Form } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import { DateTimePicker } from '@material-ui/pickers';
import { CrossButton } from '../../../Components/buttonStyle';
import { isAccessTokenValid, refreshToken } from 'services/auth-util';
import moment from 'moment';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, getCouponGroupList, couponForGroup, ...others } = props;
  const [data, setData] = React.useState(); //呈現表格用
  useEffect(() => {
    //console.log('rowData',rowData);
    if (rowData.id !== undefined) {
      //1.將coupon是否存在group的判斷 存在inGroup欄位
      for (let i = 0; i < couponForGroup.length; i++) {
        couponForGroup[i].inGroup = (rowData.coupon.find(({id}) => id == couponForGroup[i].id)) ? true : false;
      }
      //2.加進data 呈現表單資料
      setData({
        ...rowData,
        couponForGroup,
      });
    }
  }, [rowData]);

  const resetState = () => {
  };
  const handleClose = () => {
    setIsDialogOpen(false);
    resetState();
  };
  const handleOnSubmit = async values => {
    const storeCode = localStorageService.getStoreCode();
    let token = localStorageService.getToken();
    //檢查accessToken 是否過期/有效
    const validToken = await isAccessTokenValid(token);
    if (validToken.isValid === false) {
      const returnData = await refreshToken();
      token = returnData.accessToken;
    }
    // console.log('[Edit Form Dialog / handle submit]');
    setIsDialogOpen(false);

    const couponGroupData = {
      cpgCode: values.cpgCode,
      cpgName: values.cpgName,
      cpgIntro: values.cpgIntro,
      cpgGiveMode: values.cpgGiveMode,
      cpgGiveLiffUrl: values.cpgGiveLiffUrl,
      inGroupCpnCodes: [],
    };
    for (let i = 0; i < values.couponForGroup.length; i++) {
      if (values.couponForGroup[i].inGroup) {
        couponGroupData.inGroupCpnCodes.push(values.couponForGroup[i].cpnCode);
      }
    }
    console.log('couponGroupData', couponGroupData);

    ApiService.CouponGroup.modify(storeCode, couponGroupData, token).then(async response => {
      ApiService.log.add(
        storeCode,
        {
          requestUrl: `/coupon/group/modify/${storeCode}`,
          requestMethod: 'PUT',
          responseStatus: response.status,
        },
        token,
      );
      if (response.status === 200) {
        await getCouponGroupList();
      }
    });
    resetState();
  };
  const validationSchema = yup.object().shape({
    cpgCode: yup.string('coupon group Code').required('必填欄位'),
    cpgName: yup.string('coupon group Name').required('必填欄位'),
  });

  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>修改優惠群組</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={data} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>{/*左半邊*/}
                      <Field
                        required
                        as={TextField}
                        autoFocus
                        margin="dense"
                        id="cpgCode"
                        name="cpgCode"
                        label="優惠群組代碼"
                        type="text"
                        fullWidth
                        disabled
                        value={values.cpgCode}
                        onChange={handleChange}
                        error={touched.cpgCode && Boolean(errors.cpgCode)}
                        helperText={touched.cpgCode && errors.cpgCode}
                      />
                      <Field
                        required
                        as={TextField}
                        margin="dense"
                        id="cpgName"
                        name="cpgName"
                        label="優惠群組名稱"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        value={values.cpgName}
                        onChange={handleChange}
                        error={touched.cpgName && Boolean(errors.cpgName)}
                        helperText={touched.cpgName && errors.cpgName}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="cpgIntro"
                        name="cpgIntro"
                        label="優惠群組介紹"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        multiline
                        value={values.cpgIntro}
                        onChange={handleChange}
                        error={touched.cpgIntro && Boolean(errors.cpgIntro)}
                        helperText={touched.cpgIntro && errors.cpgIntro}
                      />
                    </Grid>
                    <Grid item xs={6}>{/*右半邊*/}
                    <Field required as={FormControl} margin="dense" fullWidth>
                        <InputLabel id="cpg-GiveMode">優惠發送模式</InputLabel>
                        <Select
                          labelId="cpg-GiveMode"
                          id="cpgGiveMode"
                          name="cpgGiveMode"
                          value={values.cpgGiveMode}
                          label="優惠發送模式"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={e => {
                            setFieldValue('cpgGiveMode', e.target.value);
                          }}>
                          <MenuItem value="GIVE_ALL">GIVE_ALL (發多張/數量發完為止)</MenuItem>
                          <MenuItem value="GIVE_ONE_BY_ONE">GIVE_ONE_BY_ONE (發一張/數量發完換下個券)</MenuItem>
                        </Select>
                      </Field>
                      <Field
                        as={TextField}
                        margin="dense"
                        label="優惠券派發URL(for Liff)"
                        type="text"
                        fullWidth
                        disabled={true}
                        value={'https://(client_domain)/coupongroup/?cpgCode='+values.cpgCode}
                      />
                      <Field
                        as={TextField}
                        margin="dense"
                        id="cpgGiveLiffUrl"
                        name="cpgGiveLiffUrl"
                        label="優惠券派發的Liff URL"
                        type="text"
                        fullWidth
                        disabled={rowData.expired}
                        value={values.cpgGiveLiffUrl}
                        onChange={handleChange}
                        error={touched.cpgGiveLiffUrl && Boolean(errors.cpgGiveLiffUrl)}
                        helperText={touched.cpgGiveLiffUrl && errors.cpgGiveLiffUrl}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label>
                        {"*** 此連結才能先開LINE再進LIFF URL => https://line.me/R/app/[LIFF ID] ***"}
                      </label>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray name="couponForGroup">
                        {({ push, remove, form }) => (
                          <div>
                            <label>
                              {"代碼==名稱==使用量/數量==到期日"}
                            </label>
                            {form.values.couponForGroup.map((cpn, index) => (
                              <Grid item xs={12} key={index}>
                                <label>
                                  <Field type="checkbox" name={`couponForGroup.${index}.inGroup`} />
                                  {cpn.cpnCode + "==" + cpn.cpnName + "==" + cpn.cpnQtyGiven + "/" + cpn.cpnQty + "==" + moment(cpn.cpnStopTime).format('YYYY-MM-DD')}
                                </label>
                              </Grid>
                            ))}
                            {errors.couponForGroup && typeof errors.couponForGroup === 'string' ? (
                              <div style={{ color: 'red' }}>{errors.couponForGroup}</div>
                            ) : null}
                          </div>
                        )}
                      </FieldArray>
                      {/* 不能勾選 或 不能預選
                      values.couponForGroup.map((cpn, index) => (
                        <Field
                          key={index}
                          as={FormControlLabel}
                          margin="dense"
                          control={<Checkbox id={'couponForGroup'+index} name={'couponForGroup'+index} />}
                          label={cpn.cpnCode + " / " + cpn.cpnName}
                          disabled={rowData.expired}
                          value={cpn.inGroup}
                          onChange={handleChange}
                        />
                      ))*/}
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    {rowData.expired ? (
                      <Button type="submit" color="primary" disabled={true}>
                        確定修改
                      </Button>
                    ) : (
                      <Button type="submit" color="primary" disabled={isSubmitting}>
                        確定修改
                      </Button>
                    )}
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
