import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import FormDialog from './FormDialog';
import newNameCode from 'services/nameCodeGenerator';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

let currentDate = new Date();
const initialValues = {
  cpgCode: '',
  cpgName: '',
  cpgIntro: '',
  cpgGiveMode: 'GIVE_ALL',
  cpgGiveLiffUrl: '',
};
export default function Table() {
  const classes = useStyles();
  const [couponForGroup, setCouponForGroup] = React.useState();
  const [couponGroup, setCouponGroup] = React.useState();
  React.useEffect(() => {
    getCouponForGroupList();
    getCouponGroupList();
  }, []);
  const getCouponForGroupList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.Coupon.listForGroup(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/coupon/list/forgroup/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          console.log('response', response.data);
          setCouponForGroup(response.data.couponList);
          //newName('CPG', response.data.couponGroupList.reverse());
        } else {
          console.log('couponGroup', 'coupon/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  const getCouponGroupList = () => {
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    ApiService.CouponGroup.list(storeCode)
      .then(response => {
        ApiService.log.add(
          storeCode,
          {
            requestUrl: `/coupon/group/list/${storeCode}`,
            requestMethod: 'POST',
            responseStatus: response.status,
          },
          token,
        );
        if (response.status === 200) {
          console.log('response', response.data);
          setCouponGroup(response.data.couponGroupList);
          newName('CPG', response.data.couponGroupList.reverse());
        } else {
          console.log('couponGroup', 'coupon/group/list response:' + JSON.stringify(response.data));
        }
      })
      .catch(err => console.log('err', err));
  };
  const newName = (prefix, dataList) => {
    if (dataList != undefined) {
      let nameCodeDB = dataList.map(dataInstance => {
        return dataInstance.cpgCode;
      });
      initialValues.cpgCode = newNameCode(prefix, nameCodeDB);
    }
  };
  return (
    <PageContainer heading={<IntlMessages id="pages.coupon.group.list" />}>
      <Box className={classes.section}>
        <DataTable couponGroup={couponGroup} getCouponGroupList={getCouponGroupList} couponForGroup={couponForGroup}/>
      </Box>
      <Box className={classes.section}>
        <FormDialog couponGroup={couponGroup} initialValues={initialValues} getCouponGroupList={getCouponGroupList} />
      </Box>
    </PageContainer>
  );
}
